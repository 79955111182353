@font-face {
  font-family: "Yonit-Bold";
  src: url("./fonts/Yonit-Bold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Light";
  src: url("./fonts/Yonit-Light.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Medium";
  src: url("./fonts/Yonit-Medium.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Yonit-Regular";
  src: url("./fonts/Yonit-Regular.otf");
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}